<template>
  <div class="container">
    <div class="topBox">
      <div class="topBoxLeft">
        <div class="selectLeft">
          <el-select
            v-model="periodValue"
            clearable
            placeholder="学段"
            size="mini"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="selectCenter">
          <el-select
            v-model="disciplineValue"
            clearable
            placeholder="学科"
            size="mini"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="selectRight">
          <el-select
            v-model="categoryValue"
            clearable
            placeholder="荣誉类别"
            size="mini"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="topBoxRight">更多 <i class="el-icon-d-arrow-right"></i></div>
    </div>
    <dv-scroll-board :config="config" style="width: 522px; height: 413px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      periodValue: "", // 学段
      disciplineValue: "", // 学科
      categoryValue: "", // 荣誉类别
      config: {
        header: ["教师姓名", "荣誉名称"],
        data: [
          ["李秀明", "三好学生荣誉"],
          ["张泽阳", "进步奖荣誉"],
          ["刘洋奕一", "进步奖荣誉"],
          ["李秀明", "三好学生荣誉"],
          ["张泽阳", "进步奖荣誉"],
          ["刘洋奕一", "进步奖荣誉"],
          ["李秀明", "三好学生荣誉"],
          ["张泽阳", "进步奖荣誉"],
          ["刘洋奕一", "进步奖荣誉"],
          ["李秀明", "三好学生荣誉"],
          ["张泽阳", "进步奖荣誉"],
          ["刘洋奕一", "进步奖荣誉"],
          ["张泽阳", "进步奖荣誉"],
          ["刘洋奕一", "进步奖荣誉"],
          ["李秀明", "三好学生荣誉"],
          ["张泽阳", "进步奖荣誉"],
          ["刘洋奕一", "进步奖荣誉"],
          ["张泽阳", "进步奖荣誉"],
        ],
        rowNum: 12, //表格行数
        headerBGC: "#041445", //表头
        oddRowBGC: "#041445", //奇数行
        evenRowBGC: "#1E3A7A", //偶数行
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 484px;
  background: #041445;
  border-radius: 4px;
  padding: 18px 21px 0 21px;
  box-sizing: border-box;
  .topBox {
    display: flex;
    height: 22px;
    justify-content: space-between;
    margin-bottom: 10px;
    .topBoxLeft {
      display: flex;
      height: 22px;

      .selectLeft {
        height: 22px;

        // min-width: 70px;
        // height: 22px;
        ::v-deep {
          .el-select {
            width: 90px !important;
            height: 22px !important;
          }
          .el-input {
            color: #19d9fb;
          }
          .el-input__inner {
            height: 22px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 12px;
            color: #19d9fb;
            line-height: 18px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            border-color: #1563c7;
            background-color: #04135a;
            &::placeholder {
              color: #19d9fb;
            }
          }
          .el-input__suffix {
            height: 27px;
            margin-top: -2px;
          }
          .el-select__caret {
            color: #19d9fb;
          }
          .el-select__wrap,
          .el-select-dropdown {
            background: #04135a;
            border-color: #1563c7;
          }
          .el-popper .popper__arrow::after {
            border-bottom-color: #1563c7;
          }
          .el-popper .popper__arrow {
            border-bottom-color: #1563c7;
          }
        }
      }
      .selectCenter {
        height: 22px;
        margin-left: 12px;
        margin-right: 12px;

        // min-width: 70px;
        // height: 22px;
        ::v-deep {
          .el-select {
            width: 90px !important;
            height: 22px !important;
          }
          .el-input {
            color: #19d9fb;
          }
          .el-input__inner {
            height: 22px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 12px;
            color: #19d9fb;
            line-height: 18px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            border-color: #1563c7;
            background-color: #04135a;
            &::placeholder {
              color: #19d9fb;
            }
          }
          .el-input__suffix {
            height: 27px;
            margin-top: -2px;
          }
          .el-select__caret {
            color: #19d9fb;
          }
          .el-select__wrap,
          .el-select-dropdown {
            background: #04135a;
            border-color: #1563c7;
          }
          .el-popper .popper__arrow::after {
            border-bottom-color: #1563c7;
          }
          .el-popper .popper__arrow {
            border-bottom-color: #1563c7;
          }
        }
      }
      .selectRight {
        height: 22px;

        // min-width: 70px;
        // height: 22px;
        ::v-deep {
          .el-select {
            width: 100px !important;
            height: 22px !important;
          }
          .el-input {
            color: #19d9fb;
          }
          .el-input__inner {
            height: 22px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 12px;
            color: #19d9fb;
            line-height: 18px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            border-color: #1563c7;
            background-color: #04135a;
            &::placeholder {
              color: #19d9fb;
            }
          }
          .el-input__suffix {
            height: 27px;
            margin-top: -2px;
          }
          .el-select__caret {
            color: #19d9fb;
          }
          .el-select__wrap,
          .el-select-dropdown {
            background: #04135a;
            border-color: #1563c7;
          }
          .el-popper .popper__arrow::after {
            border-bottom-color: #1563c7;
          }
          .el-popper .popper__arrow {
            border-bottom-color: #1563c7;
          }
        }
      }
    }
    .topBoxRight {
      height: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: 14px;
      color: #00af8d;
      line-height: 21px;
      text-align: right;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
      margin-top: 5px;
      .el-icon-d-arrow-right {
        font-size: 12px;
      }
    }
  }
  ::v-deep.dv-scroll-board .header .header-item {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 12px;
    color: #19d9fb;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    letter-spacing: 2px;
  }
  ::v-deep.rows {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 14px;
    color: #81c2df;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    border: 1px solid #2b387b;
  }
}
</style>