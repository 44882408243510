<template>
  <div class="container">
    <div class="topBox">
      <el-input v-model="input" placeholder="请输入教师姓名" size="mini">
        <i slot="suffix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>
    <dv-scroll-board :config="config" style="width: 100%; height: 230px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      config: {
        header: ["教师姓名", "勤", "德", "能", "绩", "附加项"],
        data: [
          ["李秀明", "80分", "80分", "80分", "80分", "80分"],
          ["张泽阳", "80分", "85分", "80分", "80分", "80分"],
          ["刘洋奕一", "80分", "80分", "85分", "80分", "80分"],
          ["李秀明", "80分", "80分", "80分", "85分", "80分"],
          ["张泽阳", "80分", "80分", "80分", "80分", "85分"],
          ["刘洋奕一", "80分", "80分", "85分", "80分", "80分"],
          ["李秀明", "80分", "80分", "80分", "85分", "80分"],
          ["张泽阳", "80分", "80分", "80分", "80分", "85分"],
          ["刘洋奕一", "80分", "80分", "85分", "80分", "80分"],
          ["李秀明", "80分", "80分", "80分", "85分", "80分"],
          ["张泽阳", "80分", "80分", "80分", "80分", "85分"],
          ["刘洋奕一", "80分", "80分", "85分", "80分", "80分"],
          ["李秀明", "80分", "80分", "80分", "85分", "80分"],
          ["张泽阳", "80分", "80分", "80分", "80分", "85分"],
          ["刘洋奕一", "80分", "80分", "85分", "80分", "80分"],
          ["李秀明", "80分", "80分", "80分", "85分", "80分"],
          ["张泽阳", "80分", "80分", "80分", "80分", "85分"],
          ["李秀明", "80分", "80分", "80分", "85分", "80分"],
        ],
        rowNum: 6, //表格行数
        headerBGC: "#041445", //表头
        oddRowBGC: "#041445", //奇数行
        evenRowBGC: "#1E3A7A", //偶数行
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 293px;
  background: #041445;
  border-radius: 4px;
  padding: 16px 17px 0 17px;
  box-sizing: border-box;
  .topBox {
    width: 210px;
    height: 22px;
    margin-bottom: 10px;
    ::v-deep.el-input__inner {
      background: #334269;
      border: 1px solid #18d5ee;
      //   caret-color: #18d5ee;
      caret-color: #fff;
      &::placeholder {
        color: #9ee8fb;
        font-size: 11px;
        line-height: 15px;
        text-align: left;
        font-style: normal;
      }
    }
    ::v-deep.el-input__inner:focus {
      color: #9ee8fb;
    }
    ::v-deep.el-input__icon {
      color: #9ee8fb;
      cursor: pointer;
    }
  }
  ::v-deep.dv-scroll-board .header .header-item {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 12px;
    color: #19d9fb;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    letter-spacing: 2px;
  }
  ::v-deep.rows {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 14px;
    color: #81c2df;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    border: 1px solid #2b387b;
  }
}
</style>