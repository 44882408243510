<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="topBox">
          <div class="topBoxLeft">XX省>合川中学</div>
          <div class="topBoxRight"></div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <!-- 荣誉榜单 -->
              <div class="leftBox">
                <div class="honorTitle">荣誉榜单</div>

                <honor />
              </div>
            </div>
            <div>
              <!-- 基础数据 -->
              <div class="centerBox">
                <div class="foundationTitle">基础数据</div>
                <foundation />
              </div>
            </div>
            <div>
              <!-- 教师综合评分雷达图 -->
              <div class="rightBox">
                <div class="teacherRatingTitle">教师综合评分雷达图</div>
                <teacherRating />
              </div>
            </div>
          </div>
          <!-- 教师综合评价展览 -->
          <div class="bototm-box">
            <div class="bottomBox">
              <div class="teacherEvaluationTitle">教师综合评价展览</div>
              <teacherEvaluation />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
// import centerLeft1 from "./centerLeft1";
// import centerLeft2 from "./centerLeft2";
// import centerRight1 from "./centerRight1";
// import centerRight2 from "./centerRight2";
// import center from "./center";
// import bottomLeft from "./bottomLeft";
// import bottomRight from "./bottomRight";
import honor from "./honor"; // 荣誉榜单
import foundation from "./foundation"; // 基础数据
import teacherEvaluation from "./teacherEvaluation"; // 教师综合评价展览
import teacherRating from "./teacherRating"; // 教师综合评分雷达图

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  components: {
    // centerLeft1,
    // centerLeft2,
    // centerRight1,
    // centerRight2,
    // center,
    // bottomLeft,
    // bottomRight,
    honor,
    foundation,
    teacherEvaluation,
    teacherRating,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  unMounted() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
</style>
<style lang="scss" scoped>
.topBox {
  display: flex;
  .topBoxLeft {
    width: 162px;
    height: 26px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 16px;
    color: #377ef6;
    // line-height: 24px;
    line-height: 50px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .topBoxRight {
    width: 1606px;
    height: 88px;
    background: rgba(4, 20, 69, 0.3);
    border: 1px solid #1563c7;
    margin-bottom: 36px;
  }
}
.leftBox {
  width: 578.31px;
  height: 543px;
  background: url("../assets/teacherImg/img.png") no-repeat;
  background-size: 100% 100%;
  padding: 0px 7px 8px 7px;
  box-sizing: border-box;
  .honorTitle {
    width: 560px;
    height: 47px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 18px;
    color: #dfe5fc;
    line-height: 27px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding-top: 15px;
  }
}
.centerBox {
  width: 640px;
  height: 543px;
  background: url("../assets/teacherImg/img.png") no-repeat;
  background-size: 100% 100%;
  padding: 0px 7px 8px 7px;
  box-sizing: border-box;
  .foundationTitle {
    width: 620px;
    height: 47px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 18px;
    color: #dfe5fc;
    line-height: 27px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding-top: 15px;
  }
}
.rightBox {
  width: 578.31px;
  height: 543px;
  background: url("../assets/teacherImg/img.png") no-repeat;
  background-size: 100% 100%;
  padding: 0px 7px 8px 7px;
  box-sizing: border-box;
  .teacherRatingTitle {
    width: 564px;
    height: 47px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 18px;
    color: #dfe5fc;
    line-height: 27px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding-top: 15px;
  }
}
.bottomBox {
  width: 1832px;
  height: 349px;
  background: url("../assets/teacherImg/bottom.png") no-repeat;
  background-size: 100% 100%;
  background-size: 100% 100%;
  padding: 0px 7px 8px 7px;
  box-sizing: border-box;
  .teacherEvaluationTitle {
    width: 1820px;
    height: 47px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 18px;
    color: #dfe5fc;
    line-height: 27px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding-top: 15px;
  }
}
</style>
