<template>
  <div class="container">
    <div class="topBox">
      <div class="topBoxLeft">
        <div class="topBoxLeftTitle">教师总数</div>
        <div class="teacherNumChart"></div>
      </div>
      <div class="topBoxLeft">
        <div class="topBoxLeftTitle">教师性别比例</div>
        <div class="centerEcharts">
          <div ref="centerEcharts" style="width: 100%; height: 100%"></div>
          <div class="labelBox">
            <div class="labelBoxNum">3876</div>
            <div class="labelBoxTitle">总人数</div>
          </div>
        </div>
        <div class="topBoxBottom">
          <div class="itemBox">
            <div class="radius" style="background: #377ef6"></div>
            <div>男:50% (600人)</div>
          </div>
          <div class="itemBox">
            <div class="radius" style="background: #00af8d"></div>
            <div>女:25% (300人)</div>
          </div>
        </div>
      </div>
      <div class="topBoxLeft">
        <div class="topBoxLeftTitle">科学教师队伍</div>
        <div class="echartsBox">
          <div
            ref="registeredGesidenceChart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
        <div class="echartsBoxBottom">
          <div class="itemBox">
            <div class="radius" style="background: #fab800"></div>
            <div>教师数</div>
          </div>
          <div class="itemBox">
            <div class="radius" style="background: #377ef6"></div>
            <div>平台师生比</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomBox">
      <div class="bottomBoxItem">
        <div class="topBoxLeftTitle">年级教师队伍</div>
        <div class="teacherChart">
          <div ref="teacherChart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="bottomBoxItem">
        <div class="topBoxLeftTitle">班级分布</div>
        <dv-capsule-chart :config="config" style="width: 100%; height: 180px" />
        <!-- <div class="classRoomChart">
          <div ref="classRoomChart" style="width: 100%; height: 100%"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        data: [
          {
            name: "二年级",
            value: 167,
          },
          {
            name: "三年级",
            value: 67,
          },
          {
            name: "四年级",
            value: 123,
          },
          {
            name: "二年级",
            value: 55,
          },
          {
            name: "三年级",
            value: 98,
          },
        ],
      },
      color: ["#377EF6", "#00AF8D"],
      fetchFwcsflData: [
        {
          value: 600,
          name: "男",
        },
        {
          value: 300,
          name: "女",
        },
      ],
      indicator: [
        { name: "语文", count: "1:4", max: 100 },
        { name: "数学", count: "1:4", max: 100 },
        { name: "英语", count: "1:4", max: 100 },
        { name: "德育", count: "1:4", max: 100 },
        { name: "美术", count: "1:4", max: 100 },
        { name: "体育", count: "1:4", max: 100 },
      ],
      echartsData: [59, 76, 91, 86, 65, 65],
      monthStr: [
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "五年级",
        "六年级",
      ],
      hjRateData: [5, 10, 10, 8, 5, 10],
      pcCountData: [5, 10, 10, 8, 5, 10],
    };
  },
  mounted() {
    this.initCenterEchartst();
    this.registeredGesidenceChart();
    this.initTeacherChart();
    this.initClassRoomChart();
  },
  methods: {
    // 教师性别比例
    initCenterEchartst() {
      let echarts = this.$echarts;
      echarts.init(this.$refs.centerEcharts).dispose();

      this.$nextTick(() => {
        this.tjryChart = this.$echarts.init(this.$refs.centerEcharts);
        this.tjryChart.setOption({
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(0, 0, 0, 0.80)", //设置背景颜色
            borderColor: "rgba(0, 0, 0, 0.80)", //设置边框颜色
            confine: true,
            textStyle: {
              color: "#FFFFFF", //设置文字颜色
            },
          },
          color: this.color,
          series: [
            {
              name: "",
              type: "pie",
              radius: ["70%", "87%"],
              //   radius: ["60%", "80%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
                color: "#4c4a4a",
                formatter: "{active|总人数}",
                rich: {
                  active: {
                    fontFamily: "PingFangSC-Regular, PingFang SC;",
                    fontSize: 14,
                    color: "#FFFFFF",
                    lineHeight: 20,
                  },
                },
              },
              emphasis: {
                //中间文字显示
                show: false,
              },
              data: this.fetchFwcsflData,
            },
          ],
        });
      });
    },
    //   学科教师队伍
    registeredGesidenceChart() {
      let echarts = this.$echarts;

      echarts.init(this.$refs.registeredGesidenceChart).dispose();
      this.$nextTick(() => {
        this.myChart = this.$echarts.init(this.$refs.registeredGesidenceChart);
        this.myChart.setOption({
          // backgroundColor: "#031d33",
          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.80)", //设置背景颜色
            borderColor: "rgba(0, 0, 0, 0.80)", //设置边框颜色

            // extraCssText: "background:",
            textStyle: {
              color: "#FFFFFF", //设置文字颜色
            },
            confine: true,
            trigger: "item", //
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },

            // formatter: `{a| ${name}}\n{b|${data.count}}`

            formatter: () => {
              //自定义绘制tooltip
              let str = "";
              for (let index = 0; index < this.indicator.length; index++) {
                // this.circle[index].name = this.circle[index].typeName;
                // data.push(this.circle[index].count);
                str += `${this.indicator[index].name}  :  ${this.indicator[index].count}<br/>`; //解决未在拐点悬浮undefine问题
              }

              return str;
            },
          },

          radar: {
            radius: "54%", //大小
            // nameGap: 1, // 图中工艺等字距离图的距离
            center: ["50%", "50%"], // 图的位置
            name: {
              rich: {
                a: {
                  fontSize: 10,
                  color: "#fff",
                  lineHeight: "10",
                },
                b: {
                  color: "#fff",
                  fontSize: 10,
                  lineHeight: "10",
                },

                triggerEvent: true,
              },

              formatter: function (name, data) {
                // return `{a| ${name}}{b|${data.count}}`;
                return `{a|${name}}{b|(${data.count})}`;
              },
            },

            indicator: this.indicator,

            axisLine: {
              lineStyle: {
                color: "#939BBC",
              },
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: "#939BBC", // 图表背景的颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: "#939BBC", // 设置网格的颜色
              },
            },
          },

          series: [
            {
              name: "科学教师队伍",
              type: "radar",
              symbol: "angle",
              itemStyle: {
                normal: {
                  areaStyle: { type: "default" },
                },
              },
              data: [
                {
                  symbol: "circle",
                  symbolSize: 5,
                  value: this.echartsData,
                  areaStyle: {
                    color: "#5C592D",
                  },
                  itemStyle: {
                    normal: {
                      borderWidth: 1,
                      color: "#FAB800",
                      borderColor: "#FAB800",
                    },
                  },
                  lineStyle: {
                    color: "#FAB800",
                    width: 1,
                  },
                },
              ],
            },
          ],
        });
      });
    },
    // 年级教师柱形图
    initTeacherChart() {
      let echarts = this.$echarts;
      echarts.init(this.$refs.teacherChart).dispose();
      this.$nextTick(() => {
        this.myChart = this.$echarts.init(this.$refs.teacherChart);
        const option = {
          grid: {
            top: "27%",
            right: 0,
            left: 0,
            bottom: 10,
            containLabel: true,
          },
          legend: {
            orient: "horizontal",
            x: "right",
            y: "top",
            padding: 10,
            textStyle: {
              fontSize: 12,
              color: "#FFFFFF",
            },
            itemWidth: 6,
            itemHeight: 6,
          },
          toolbox: {
            show: false,
            orient: "horizontal",
            y: "20px", //距离y轴多远
            x: "right", //距离y轴多远

            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
            },
          },
          dataZoom: [
            {
              //添加dataZoom属性即可显示滑动条
              type: "slider",
              show: true, //flase直接隐藏图形
              xAxisIndex: [0],
              left: "9%", //滚动条靠左侧的百分比
              bottom: -5,
              start: 0, //滚动条的起始位置
              end: 50, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            },
          ],

          xAxis: {
            type: "category",
            // data: that.monthArr,
            data: this.monthStr,

            axisLabel: {
              color: "#fff",
              // fontSize: 12,
              // fontSize: fontSize(0.13),
              // fontWeight: 'lighter',
              interval: 0, //强制文字产生间隔
              // rotate: 45, //文字逆时针旋转45°
              overflow: "truncate",
              ellipsis: "...",
              fontSize: 10,
              fontWeight: "300",
            },
            axisLine: {
              // symbol: "arrow",
              lineStyle: {
                // type: "dashed",
                // color: "#1744A7",
                type: "solid", // 线的类型
                opacity: 0.5, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                // ...
              },
            },
            axisTick: {
              show: false,
              // alignWithLabel: true,
            },
          },
          yAxis: [
            {
              // data: this.regionData,

              nameTextStyle: {
                // color: "rgba(255,255,255,0.8)",
                fontSize: 0.16,
                lineHeight: 0.5,
              },
              axisLine: {
                show: false, // 是否显示x轴分割线

                lineStyle: {
                  type: "dashed",
                },
              },
              type: "value",
              axisLabel: {
                interval: 0,
                color: "#fff",
                fontSize: 10,
                fontWeight: "300",
              },
              splitLine: {
                // show: true,
                show: true, // 是否显示x轴分割线
                interval: "auto", // 坐标轴分隔线的显示间隔
                lineStyle: {
                  // color: ["#40A9FF"], // 分隔线颜色。
                  color: ["#40A9FF"], // 分隔线颜色。
                  width: 1, // 分隔线线宽
                  type: "dashed", // 线的类型
                  opacity: 0.5, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                },
              },
              axisTick: {
                show: false,
              },
            },
            {
              show: false,
            },
          ],
          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.80)", //设置背景颜色
            borderColor: "rgba(0, 0, 0, 0.80)", //设置边框颜色

            // extraCssText: "background:",
            textStyle: {
              color: "#FFFFFF", //设置文字颜色
            },
            // formatter: " name",
            confine: true,
            trigger: "item", //axis
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          series: [
            {
              name: "正确率",
              type: "line", //类型 折线
              smooth: false, //是否是弯曲的折现  弯弯曲曲
              itemStyle: {
                //折线样式
                /*设置折线颜色*/
                // normal: {
                color: "#B37FEB",
                // },
              },
              data: this.hjRateData, //折线的点
              yAxisIndex: 1,
            },
            {
              name: "完成数",
              barWidth: 12,
              data: this.pcCountData,
              type: "bar",
              stack: "bar",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(24,144,255,0.05)",
                borderColor: "rgba(24,144,255,0.05)",
                borderWidth: 0.1,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    //只要修改前四个参数就ok
                    offset: 1,
                    color: "#36CFC9",
                  },
                  {
                    offset: 0,
                    color: "#2F54EB",
                  },
                ]),
              },
            },
          ],
        };
        this.myChart.setOption(option);
      });
    },
    // 年级教师柱形图
    initClassRoomChart() {
      let echarts = this.$echarts;
      echarts.init(this.$refs.classRoomChart).dispose();
      this.$nextTick(() => {
        this.myChart = this.$echarts.init(this.$refs.classRoomChart);
        const option = {
          grid: {
            top: "27%",
            right: 12,
            left: 0,
            bottom: 10,
            containLabel: true,
          },
          legend: {
            orient: "horizontal",
            x: "right",
            y: "top",
            padding: 10,
            textStyle: {
              fontSize: 12,
              color: "#FFFFFF",
            },
            itemWidth: 6,
            itemHeight: 6,
          },
          toolbox: {
            show: false,
            orient: "horizontal",
            y: "20px", //距离y轴多远
            x: "right", //距离y轴多远

            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
            },
          },

          xAxis: {
            type: "category",
            // data: that.monthArr,
            data: this.monthStr,

            axisLabel: {
              color: "#fff",
              // fontSize: 12,
              // fontSize: fontSize(0.13),
              // fontWeight: 'lighter',
              interval: 0, //强制文字产生间隔
              // rotate: 45, //文字逆时针旋转45°
              overflow: "truncate",
              ellipsis: "...",
              fontSize: 10,
              fontWeight: "300",
            },
            axisLine: {
              // symbol: "arrow",
              lineStyle: {
                // type: "dashed",
                // color: "#1744A7",
                // type: "solid", // 线的类型
                opacity: 0.5, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                // ...
              },
            },
            axisTick: {
              show: false,
              // alignWithLabel: true,
            },
          },
          yAxis: [
            {
              // data: this.regionData,

              nameTextStyle: {
                // color: "rgba(255,255,255,0.8)",
                fontSize: 0.16,
                lineHeight: 0.5,
              },
              axisLine: {
                lineStyle: {
                  type: "dashed",
                },
              },
              type: "value",
              axisLabel: {
                interval: 0,
                color: "#fff",
                fontSize: 10,
                fontWeight: "300",

                // color: "rgba(255,255,255,0.8)",
                // fontSize: fontSize(0.13),

                // fontWeight: 'lighter',
              },
              splitLine: {
                // show: true,
                show: false, // 是否显示x轴分割线
                interval: "auto", // 坐标轴分隔线的显示间隔
                lineStyle: {
                  // color: ["#40A9FF"], // 分隔线颜色。
                  color: ["#40A9FF"], // 分隔线颜色。
                  width: 1, // 分隔线线宽
                  type: "dashed", // 线的类型
                  opacity: 0.5, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.80)", //设置背景颜色
            borderColor: "rgba(0, 0, 0, 0.80)", //设置边框颜色

            // extraCssText: "background:",
            textStyle: {
              color: "#FFFFFF", //设置文字颜色
            },
            // formatter: " name",
            confine: true,
            trigger: "item", //axis
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          series: [
            {
              name: "完成数",
              barWidth: 16,
              data: this.pcCountData,
              type: "bar",
              stack: "bar",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(24,144,255,0.05)",
                borderColor: "rgba(24,144,255,0.05)",
                borderWidth: 0.1,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    //只要修改前四个参数就ok
                    offset: 1,
                    color: "#36CFC9",
                  },
                  {
                    offset: 0,
                    color: "#2F54EB",
                  },
                ]),
              },
            },
          ],
        };
        this.myChart.setOption(option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 484px;
  .topBox {
    width: 100%;
    display: flex;
    height: 240px;
    justify-content: space-between;
    margin-bottom: 10px;
    .topBoxLeft {
      width: 204px;
      height: 100%;
      background: #041445;
      border-radius: 4px;
      padding: 16px;
      box-sizing: border-box;
      .topBoxLeftTitle {
        color: #19d9fb;
        margin-bottom: 20px;
      }
      .teacherNumChart {
        width: 100%;
        height: 160px;
        background: url("../assets/teacherImg/radius.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .centerEcharts {
      width: 100%;
      height: 120px;
      position: relative;
      .labelBox {
        position: absolute;
        top: 34%;
        left: 35%;
        .labelBoxNum {
          font-size: 22px;
        }
        .labelBoxTitle {
          font-size: 12px;
          color: #19d9fb;
          text-align: center;
        }
      }
    }
    .topBoxBottom {
      text-align: center;
      margin-top: 10px;
      .radius {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .itemBox {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
    }

    .echartsBox {
      width: 100%;
      height: 130px;
    }
    .echartsBoxBottom {
      .radius {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .itemBox {
        display: flex;
        // justify-content: center;
        margin-bottom: 10px;
        font-size: 14px;
        margin-left: 30px;
      }
    }
  }
  .bottomBox {
    width: 100%;
    height: 235px;
    display: flex;
    justify-content: space-between;
    .bottomBoxItem {
      width: 49.5%;
      height: 100%;
      background: #041445;
      border-radius: 4px;
      padding: 16px;
      box-sizing: border-box;
      .topBoxLeftTitle {
        color: #19d9fb;
        margin-bottom: 20px;
      }
    }
    .teacherChart {
      width: 100%;
      height: 180px;
    }
    .classRoomChart {
      width: 100%;
      height: 180px;
    }
  }
}
</style>