<template>
  <div class="container">
    <!-- <dv-charts :option="options" /> -->
    <div class="echartsBox">
      <div
        ref="registeredGesidenceChart"
        style="width: 100%; height: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indicator: [
        { name: "德", count: 59, max: 100 },
        { name: "能", count: 76, max: 100 },
        { name: "勤", count: 91, max: 100 },
        { name: "绩", count: 86, max: 100 },
        { name: "附加项", count: 65, max: 100 },
      ],
      echartsData: [59, 76, 91, 86, 65],
    };
  },
  mounted() {
    this.registeredGesidenceChart();
  },
  methods: {
    registeredGesidenceChart() {
      let echarts = this.$echarts;

      echarts.init(this.$refs.registeredGesidenceChart).dispose();
      this.$nextTick(() => {
        this.myChart = this.$echarts.init(this.$refs.registeredGesidenceChart);
        this.myChart.setOption({
          // backgroundColor: "#031d33",
          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.80)", //设置背景颜色
            borderColor: "rgba(0, 0, 0, 0.80)", //设置边框颜色

            // extraCssText: "background:",
            textStyle: {
              color: "#FFFFFF", //设置文字颜色
            },
            confine: true,
            trigger: "item", //
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },

            // formatter: `{a| ${name}}\n{b|${data.count}}`

            formatter: () => {
              //自定义绘制tooltip
              let str = "";
              for (let index = 0; index < this.indicator.length; index++) {
                // this.circle[index].name = this.circle[index].typeName;
                // data.push(this.circle[index].count);
                str += `${this.indicator[index].name}  :  ${this.indicator[index].count}<br/>`; //解决未在拐点悬浮undefine问题
              }

              return str;
            },
          },

          radar: {
            // radius: "80%", //大小
            // nameGap: 1, // 图中工艺等字距离图的距离
            center: ["50%", "50%"], // 图的位置
            name: {
              rich: {
                a: {
                  fontSize: 16,
                  color: "#fff",
                  lineHeight: "17",
                },
                b: {
                  color: "#fff",
                  fontSize: 16,
                  lineHeight: "17",
                },

                triggerEvent: true,
              },

              formatter: function (name, data) {
                // return `{a| ${name}}{b|${data.count}}`;
                return `{a|${name}}{b|(${data.count})}`;
              },
            },

            indicator: this.indicator,

            axisLine: {
              lineStyle: {
                color: "#939BBC",
              },
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: "#939BBC", // 图表背景的颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: "#939BBC", // 设置网格的颜色
              },
            },
          },

          series: [
            {
              name: "教师综合评分雷达图",
              type: "radar",
              symbol: "angle",
              itemStyle: {
                normal: {
                  areaStyle: { type: "default" },
                },
              },
              data: [
                {
                  symbol: "circle",
                  symbolSize: 5,
                  value: this.echartsData,
                  areaStyle: {
                    color: "#5C592D",
                  },
                  itemStyle: {
                    normal: {
                      borderWidth: 1,
                      color: "#FAB800",
                      borderColor: "#FAB800",
                    },
                  },
                  lineStyle: {
                    color: "#FAB800",
                    width: 1,
                  },
                },
              ],
            },
          ],
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 484px;
  background: #041445;
  border-radius: 4px;
  padding-top: 63px;
  box-sizing: border-box;
  .echartsBox {
    width: 435px;
    height: 339px;
    margin-left: 44px;
  }
}
</style>